import { Combobox } from "@headlessui/react";
import {
  IconArrowRight,
  IconChevronDown,
  IconSearch,
} from "@tabler/icons-react";
import React from "react";
import { SystemMessage } from "../../shared/SystemMessage";

import classNames from "classnames";
import { partition } from "fp-ts/lib/Array";
import { BodyPart } from "../types";
import {
  itemStyles,
  labelStyles,
  optionsStyles,
  targetStyles,
} from "./inputStyles";
import { pipe } from "fp-ts/lib/function";
import { ConsultationFooter } from "./ConsultationFooter";

interface BodyPartInputProps {
  name?: string;
  availableBodyParts: BodyPart[];
  maxBodyParts?: number;
  selectedBodyPartIds: number[];
  setSelectedBodyPartIds: (id: number[]) => void;
  errorId?: string;
  isGrouped?: boolean;
  inputClassName?: string;
  showConsultationFooter?: boolean;
}

export function BodyPartInput({
  name = "body-parts",
  availableBodyParts,
  maxBodyParts = 4,
  selectedBodyPartIds,
  setSelectedBodyPartIds,
  isGrouped,
  errorId,
  inputClassName,
  showConsultationFooter,
}: BodyPartInputProps) {
  const comboButton = React.useRef<HTMLButtonElement | null>(null);
  const [query, setQuery] = React.useState<string>("");

  const filterByQuery = (items: BodyPart[]) => {
    return Boolean(query)
      ? items.filter(({ name }) =>
          name.toLowerCase().includes(query.toLowerCase())
        )
      : items;
  };

  const { unselectedParts, selectedParts } = pipe(
    availableBodyParts,
    partition(({ id }) => selectedBodyPartIds?.includes(id)),
    ({ left, right }) => ({
      selectedParts: right,
      unselectedParts: filterByQuery(left),
    })
  );

  const warningId = `${name}-warning`;

  const totalParts = selectedBodyPartIds.length;
  const partLimitExceeded = totalParts >= maxBodyParts;

  React.useEffect(() => {
    // Allow tab to focus to work.
    if (comboButton.current) {
      comboButton.current.setAttribute("tabindex", "auto");
    }
  }, [comboButton]);

  return (
    <Combobox
      name={name}
      data-testid="body-parts-input"
      as="div"
      aria-invalid={!!errorId}
      aria-errormessage={errorId}
      className="relative"
      value={selectedBodyPartIds}
      multiple
      onChange={(value) => {
        setQuery("");
        setSelectedBodyPartIds(value);
        if (comboButton.current) comboButton.current.click();
      }}
    >
      <Combobox.Button
        as="button"
        ref={comboButton}
        className={targetStyles(isGrouped)}
      >
        <div>
          {Boolean(totalParts) && (
            <div className={labelStyles}>
              Body Parts
              <span className="grid h-[18px] w-[18px] place-items-center rounded-full bg-neutral-200 text-xs font-semibold text-neutral-950">
                {totalParts}
              </span>
            </div>
          )}
          <span className="line-clamp-1">
            {selectedParts.map(({ name }) => name || "").join(", ") ||
              "Body Parts"}
          </span>
        </div>
        <IconChevronDown className="ml-auto h-6 w-6 shrink-0 text-neutral-700" />
      </Combobox.Button>
      <Combobox.Options
        className={classNames(
          optionsStyles,
          "max-h-[400px] scroll-p-20",
          showConsultationFooter && "pb-0"
        )}
      >
        <div className="mb-2 flex gap-x-2 rounded-lg border border-neutral-300 p-2">
          <IconSearch className="h-5 w-5 shrink-0" />
          <Combobox.Input
            displayValue={() => query}
            onChange={(e) => setQuery(e.target.value)}
            className="w-full focus:outline-none"
          />
        </div>

        {partLimitExceeded && (
          <SystemMessage
            message="Body part limit reached"
            type="warning"
            id={warningId}
            role="status"
            className="!mb-2"
          />
        )}

        {unselectedParts.length === 0 && !selectedParts.length && (
          <p className="flex min-h-[200px] items-center justify-center text-base font-medium text-neutral-700">
            No body parts found
          </p>
        )}

        {selectedParts.map((bp) => (
          <Combobox.Option key={bp.id} value={bp.id} className={itemStyles}>
            <input
              type="checkbox"
              defaultChecked={true}
              readOnly
              className="custom-checkbox-input"
            />
            {bp.name}
          </Combobox.Option>
        ))}

        {!partLimitExceeded &&
          unselectedParts.map((bp) => (
            <Combobox.Option key={bp.id} value={bp.id} className={itemStyles}>
              <input
                type="checkbox"
                defaultChecked={false}
                readOnly
                className="custom-checkbox-input"
              />
              {bp.name}
            </Combobox.Option>
          ))}
        {showConsultationFooter && <ConsultationFooter />}
      </Combobox.Options>
    </Combobox>
  );
}
